import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./kit.scss";

import SectionTitle from "../common/SectionTitle";
import KitBox from "./KitBox";

export default class Kit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registers: [
        {
          image: '/assets/images/icons/kit-inicial/Prancheta 1.png',
          imageDescription: 'bolsa térmica',
          title: 'Bolsa térmica',
          description: '* Exclusivo do primeiro kit inicial'
        },
        {
          image: '/assets/images/icons/kit-inicial/Prancheta 2.png',
          imageDescription: 'agulhas',
          title: "Kit de agulhas e coletor de materiais perfurocortantes",
          description: '* Materiais complementares para todas as aplicações de uso pediátrico',
        },
        {
          image: '/assets/images/icons/kit-inicial/Prancheta 4.png',
          imageDescription: 'documento com instruções',
          title: "Guia do paciente",
          description: 'com informações de como manusear e reconstituir o produto',
        },
        {
          image: '/assets/images/icons/kit-inicial/Prancheta 3.png',
          imageDescription: 'balão de conversas',
          title: "Informações",
          description: 'sobre as aplicações e melhor adesão ao tratamento e conservação',
        },
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-purple"
          style={{
            paddingBottom: '0px',
            backgroundColor: '#dde0f7',
            position: 'relative',
            ...this.props.styles,
          }}
        >
          <Container>
            <SectionTitle
              title="Kit Inicial"
              styleTitle="section-kit-title"
              desc="Nós forneceremos os materiais complementares para o uso seguro e correto do medicamento, além de materiais, informações e central de atendimento especializado."
              styleSubtitle="section-kit-subtitle"
            />

            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row className="mt-4 justify-content-center">
                  <KitBox registers={this.state.registers} />
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
