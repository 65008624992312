export default function isOfLegalAge(value) {
  let year = value.getFullYear(),
      month = value.getMonth(),
      day = value.getDate();

  const d = new Date();
  let currentYear = d.getFullYear(),
      currentMonth = d.getMonth() + 1,
      currentDay = d.getDate();

  year = +year;
  month = +month;
  day = +day;

  let age = currentYear - year;
  if ((currentMonth < month || currentMonth === month) && currentDay < day) age--;
  return age >= 18
    ? true
    : false;
}
